import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthContext } from "../context";
import login_img from '../assets/login_img.png';
import { LuLoader2 } from "react-icons/lu";

const Signup = () => {
  const {token,loader, registerHandle} = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword : "",
  });

  const handleMobileChange = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue === "" ||
      (/^\d+$/.test(inputValue) && inputValue?.length <= 10)
    ) {
      setUserDetails({
        ...userDetails,
        mobile: inputValue,
      });
    }
  };
  const isPasswordValid = (password) => {
    // Password should contain at least one uppercase letter, one lowercase letter,
    // one number, and one special character
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/;
    return passwordRegex.test(password);
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (Object.values(userDetails).some((value) => value === "")) {
      toast.error("Please fill all required fields");
    } else if (userDetails.mobile?.length < 10) {
      toast.error("Mobile number should be at least 10 digits");
    } else if (userDetails.password?.length < 8) {
      toast.error("Password should be more than 8 digits");
    } else if (!isPasswordValid(userDetails.password)) {
      Swal.fire({
        icon: "error",
        title: "Password validation error",
        text: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character(@,_,$,%)",
      });
    }else if (userDetails.password !== userDetails.confirmPassword) {
      toast.error("Password and confirm password do not match");
    } else {
      registerHandle(userDetails)
    }
  };

  useEffect(() => {
    let timeoutId;

    if (token) {
        timeoutId = setTimeout(() => {
            navigate(location?.state?.from?.pathname ?? "/");
        }, 1000);
    }

    // Cleanup function to clear the timeout if the component unmounts or if the token changes
    return () => {
        clearTimeout(timeoutId);
    };
}, [token, location?.state?.from?.pathname, navigate]);

  return (
    <div className="container-fluid">
      <div className="container-sm relative">
      <div className="row items-center py-4">
        <div className="col-lg d-none d-lg-block">
          <img src={login_img} alt="" className="object-cover" />
        </div>
        <div className="col-lg">
          <span className="absolute top-4 right-4">
            <Link to="/" className="flex items-center gap-2 text-[#222831]">
              <FaHome className="h6 text-[#222831]" /> Back to home
            </Link>
          </span>
          <h2 className="text-3xl py-2 text-[#222831] font-semibold">Sign up here</h2>
          <p>
            Already have an account ?{" "}
            <Link to="/login" className="text-primary font-primary">
              Login in here
            </Link>
          </p>
          <form onSubmit={handleClick}>
            <div className="row mt-4 mb-3 gap-3">
              <div className="form-group col-lg">
                <label htmlFor="first_name" className="h6"> First Name <sup className="text-danger">*</sup></label>
                <input
                  type="text"
                  placeholder="Enter your first name"
                  className="form-control"
                  value={userDetails.first_name}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      first_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group col-lg">
                <label htmlFor="last_name" className="h6">
                  Last Name <sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  placeholder="Enter your last name"
                  className="form-control"
                  value={userDetails.last_name}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      last_name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="mobile" className="h6">
                Mobile <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                placeholder="Enter your mobile number"
                className="form-control"
                value={userDetails.mobile}
                onChange={handleMobileChange}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email" className="h6">
                Email <sup className="text-danger">*</sup>
              </label>
              <input
                type="email"
                placeholder="Enter your email"
                className="form-control"
                value={userDetails.email}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password" className="h6">
                Password <sup className="text-danger">*</sup>
              </label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className="form-control"
                value={userDetails.password}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    password: e.target.value,
                  })
                }
              />
              <p className="flex items-center gap-2 text-[14px]">
                <input onClick={() => setShowPassword(!showPassword)} type="checkbox"  /> Show password</p>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="confirmPassword" className="h6">
                Confirm password <sup className="text-danger">*</sup>
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Enter your password"
                className="form-control"
                value={userDetails.confirmPassword}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    confirmPassword: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group flex justify-between items-center py-4">
            <button type='submit' className='btn btn-dark bg-[#222] rounded-none w-25 flex items-center justify-center gap-2' disabled={loader} >
              {loader ? <>Register <LuLoader2 className='animate-spin' /></>  :"Register"}
            </button>
              <p className="text-[14px]">
                Get help in signing in.{" "}
                <Link className="text-primary font-bold">Get help now</Link>
              </p>
            </div>
          </form> 
        </div>
      </div>
      </div>
    </div>
  );
};

export default Signup;
