
const baseUrl = "https://api.digiuncle.co.in"
// const BaseUrl  = "http://localhost:5500"

// users Api urls 

export const SignUpUrl = `${baseUrl}/user/create/`

export const LoginUrl = `${baseUrl}/user/login/`

export const GetUserUrl = `${baseUrl}/user/get/`

export const UpdateUserUrl = `${baseUrl}/user/update/`

// product Api urls 

export const GetProductUrl = `${baseUrl}/product/get/`

// user Informations urls 

export const GetUserInformation = `${baseUrl}/information/get/`

export const CreateUserInformation = `${baseUrl}/information/create`

export const DeleteUserInformation = `${baseUrl}/information/delete/`


// category urls 

export const GetCategoryUrl = `${baseUrl}/category/get-all`

// cart urls

export const AddtoCArt = `${baseUrl}/cart/add-to-cart`
export const RemoveCArt = `${baseUrl}/cart/remove-to-cart/`
export const Addqty = `${baseUrl}/cart/add-qty/`

