import React from 'react'
import { FaRegClock } from "react-icons/fa";

const CouponsBook = () => {
  return (
    <>
      <div className="row justify-center">
        <div className="col-lg-10">
          <div className="col">
            <p>My Coupons</p>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-header flex items-center justify-between">
                <h4>Flat 30% OFF on Home & decor</h4> <span className='text-[12px] text-secondary flex items-center gap-3'><FaRegClock /> valid till 23 Mar</span>
              </div>
              <div className="card-body">
                <h5 className="card-title">Special Gift Coupon</h5>
                <p className="card-text mb-3">With supporting text below as a natural lead-in to additional content.</p>
                <button className="btn bg-green-500 hover:bg-green-600 text-white w-50 p-1 rounded-0"  >Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CouponsBook