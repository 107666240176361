import React from 'react'
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";

const Reviews = () => {
  return (
    <>
        <div className="container-fluid">
           <div className="container-sm pt-4">
                <h3 className='fs-2'>Ratings and Reviews</h3>
                <div className="row justify-center my-3">
                    <div className="col-lg-11 border py-3 px-3 rounded">
                        <div className='flex items-center gap-2'>
                            <button className='btn btn-success'>5</button>
                            <span>Excellent</span>
                        </div>
                        <p className='message py-2'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus atque minus veritatis recusandae debitis ea dolores, laudantium porro ratione commodi quidem! Eum dolorum voluptatibus repudiandae esse aliquam harum dolorem earum.
                        </p>
                        <div className="row justify-end">
                            <div className="col-3 flex justify-end gap-3">
                                <span className='fs-5 flex items-center gap-2'><AiOutlineLike/> 12</span>
                                <span className='fs-5 flex items-center gap-2'><AiOutlineDislike /> 21</span>
                            </div>
                        </div>
                    </div>
                </div>
               
           </div>
        </div>
    </>
  )
}

export default Reviews