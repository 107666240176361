import React from 'react'

const CheckOut = () => {
  return (
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Add Shipping information</h1>
        <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <table className='table'>
            <thead>
                <tr>
                    <th>Total items</th>
                    <td>2</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Price</th>
                    <td></td>
                </tr>
                <tr>
                    <th>Discount</th>
                    <td></td>
                </tr>
                <tr>
                    <th>Delivery charge</th>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <div className="col py-2">
            <ul className='nav flex-column gap-2'>
                <li className='nav-item'>
                    <div className="form-check">
                        <input type="radio" className='form-check-input' name="flexRadioDefault" id="flexRadioDefault1" />
                        <label htmlFor="">Pay Online</label>
                    </div>
                </li>
                <li className='nav-item'>
                    <div className="form-check">
                        <input type="radio" className='form-check-input' name="flexRadioDefault" id="flexRadioDefault1" />
                        <label htmlFor="">Cash on delivery</label>
                    </div>
                </li>
            </ul>
        </div>
        <div className="col flex items-center gap-3">
            <h4>total amount payble</h4>
            <button className='btn btn-dark'>Place order</button>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default CheckOut