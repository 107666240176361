import React from "react";
import { Link } from "react-router-dom";
import { IoSearch, IoCartOutline } from "react-icons/io5";
import { FiUser } from "react-icons/fi";
import logo_img from '../../assets/PNG/logo.png'

import { useCartContext } from "../../context";
import Search from "../search/Search";

const Navbar = () => {
 
  const {cartItems} = useCartContext()

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light sticky-top py-1">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo_img} alt="" width={180} className="p-2" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <Link className="offcanvas-title" id="offcanvasNavbarLabel">
                <img src={logo_img} alt="" width={120} />
              </Link>
              <button type="button text-dark" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav m-auto gap-6">
                <li className="nav-item" data-bs-dismiss='offcanvas'>
                  <Link to='/' className="nav-link fw-bold">Home</Link>
                </li>
                <li className="nav-item" data-bs-dismiss='offcanvas'>
                  <Link to='/shop' className="nav-link fw-bold">Shop</Link>
                </li>
                <li className="nav-item" data-bs-dismiss='offcanvas'>
                  <Link to='about' className="nav-link fw-bold">About</Link>
                </li>
                <li className="nav-item" data-bs-dismiss='offcanvas'>
                  <Link to='contact' className="nav-link fw-bold">Contact</Link>
                </li>
              </ul>
              <ul className="navbar-nav justify-content-end">
                <li className="nav-item" data-bs-dismiss='offcanvas'>
                  <Link to="" className="nav-link flex items-center gap-2" data-bs-toggle="modal" data-bs-target="#exampleModal1"><IoSearch size={25} /> <span className="d-lg-none d-sm-flex">Search Products</span></Link>
                </li>
                <li className="nav-item" data-bs-dismiss='offcanvas'>
                  <Link to="/profile" className="nav-link flex items-center gap-2"><FiUser size={25} /><span className="d-lg-none d-sm-flex">My account</span></Link>
                </li>
                <li className="nav-item" data-bs-dismiss='offcanvas'>
                  <Link to="/cart" className="nav-link fw-bold flex items-center text-[18px] relative"><IoCartOutline size={25} /><sup className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{cartItems?.length ? cartItems?.length : 0}</sup> <span className="d-lg-none ms-2 d-sm-flex">Cart</span></Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <Search />
      <p className="hr"></p>
    </>
  );
};

export default Navbar;
