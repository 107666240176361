
import React, { useState } from 'react'

import { useUserContext } from '../../context'
import AddressForm from './AddressForm'

const AddressBook = () => {
    const { information,DeleteInformation, data } = useUserContext()
    const [accordion, setAccordion] = useState(false)
    

    return (
        <>
            <div className="row justify-center py-3 bg-[#a55233]">
                <div className="col-lg-10">
                    <h3 className="text-2xl fw-bold text-light">Manage Address</h3>
                </div>
            </div>
            <div className="row justify-center py-3">
                <div className="col-lg-10">
                    <div className="col py-3">
                        <div className="row">
                            <button onClick={() => setAccordion(!accordion)} className='btn btn-outline-primary fs-5'>Add new address</button>
                        </div>
                        <div className={`row bg-slate-100 transition-all duration-500 ${accordion ? `` : `h-0 overflow-hidden`}`} >
                            <AddressForm setAccordion={setAccordion}/>
                        </div>
                    </div>

                </div>

                {
                    information?.map((item,index) => {
                        return (
                            <div className="col-lg-10 border border-secondary py-2 px-2 mb-3" key={index}>
                                <span>{data?.firstName}</span><br/>
                                <span>{data?.mobile}</span><br/>
                                <span>{item.addressLine1}, {item.landmark}  </span>
                                <span> {item.city} , {item.state} , {item.pincode}</span>
                                <p>
                                    <button className='text-primary' onClick={()=>DeleteInformation(item.info_id)}>Delete Address</button>
                                </p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default AddressBook;