import React, { useEffect, useState } from 'react'
import { Loader, Navbar } from '../components'
import { Outlet } from 'react-router-dom'
import { Footer } from '../pages'

const SharedLayout = () => {
  const [loading, setLoading] = useState(false);
 

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  return (
    <>
        {loading ? <Loader /> :
        <>
          <Navbar />
          <Outlet />
          <Footer/>
        </> 
        }
    </>
  )
}

export default SharedLayout