import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { useProductContext } from "../../context";
import ReactStars from "react-rating-stars-component";
import Allloader from "../loader/Allloader";





const ProductCard = ({ data }) => {
  const { allLoader } = useProductContext();  

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="container-sm py-3">
          <Carousel responsive={responsive} ssr={true}>
            {data?.map((product, index) => {
              const imagees = product?.image.split(",")    
                   
              let urlWithoutBrackets = imagees[0].replace(/^\[|\]$/g, ''); 
             
              const image = JSON.parse(urlWithoutBrackets)
              
              
              return (
              <div key={index} className="col product-card relative">
                {allLoader ? (
                  <Allloader />
                ) : (
                  <div className="card ms-2 rounded-none border hover:shadow-2xl">
                    <Link
                      to={`/product/${product.product_id}/`}
                      className="nav-link flex justify-center"
                      onClick={()=>{handleClick()}}
                    >
                      <img
                        src={image}
                        alt=""
                        className="img-fluid min-h-[40vh] lg:h-[45vh]"
                      />
                    </Link>
                    <div className="card-body py-3">
                      <Link
                        to={`/product/${product.product_id}/`}
                        className="fs-6 text-secondary"
                        onClick={handleClick}
                      >
                        {product.name?.slice(0, 25)}
                      </Link>
                      <ReactStars
                        count={product.rating}
                        size={20}
                        activeColor="#ffc93c"
                        isHalf={true}
                        value={3}
                        edit={false}
                      />
                      <div className="lg:flex items-end justify-between">
                          {product.MRP_price ? (
                            <div className="flex gap-3 items-center">
                              <del className="text-[10px] text-gray-400 font-semibold lg:text-[13px]">
                                Rs. {product.MRP_price}
                              </del>
                              <h6 className=" fs-6 font-bold text-dark">
                                Rs. {product.selling_price}
                              </h6>
                            </div>
                          ) : (
                            <p className="font-bold text-dark">
                              Rs. {product.selling_price}
                            </p>
                          )}
                      </div>
                    </div>
                    {/* <div className="card-hover">
                      <span onClick={() => {token ? ADDTOCART(product,image): naviagte('/login')}} className="btn text-3xl text-[#b88e2f] p-2 shadow-lg rounded-lg"><IoBagOutline /></span>
                      <span className="btn text-3xl text-rose-600 p-2 rounded-lg shadow-lg"><IoMdHeartEmpty /></span>
                    </div> */}
                  </div>
                )}
              </div>
            )})}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
