import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { FaClock } from "react-icons/fa";




const Contact = () => {
  return (
    <>
        <div className="container-fluid">
            <div className="row items-center justify-center min-h-[35vh] bg-gradient-golden">
                <div className="col-lg-3">
                    <h2 className="text-center display-4 font-bold text-light">Contact us</h2>
                </div>
            </div>
        </div> 
        <div className="container-fluid py-4">
            <div className="container-sm">
                <div className="row justify-center">
                    <div className="col-lg">
                        <div className="col-lg">
                            <h4 className='fs-3 font-semibold text-center'>Get in touch with us</h4>
                            <p className='text-center text-secondary'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus quibusdam, nam odio impedit tenetur aliquid quos aliquam modi voluptatibus! Porro.</p>
                        </div>
                        <div className="row py-6 gap-8">
                            <div className="col-lg-3">
                                <div className="row">
                                    <div className="col-lg-12 flex gap-2">
                                        <p>
                                            <FaLocationDot/>
                                        </p>
                                        <address>
                                            <span className='h6'>Address :</span>
                                            <p className='text-[12px]'>Achievers Center Point Mall, Sec-49, Kalindi hills, NIT Faridabad, Haryana 121001</p>
                                        </address>
                                    </div>
                                    <div className="col-lg-12 flex gap-2">
                                        <p>
                                            <IoCall/>
                                        </p>
                                        <address>
                                            <span className='h6'>Phone :</span>
                                            <p className='flex items-center justify-center gap-1'>
                                                <span className='text-[12px]'>Phone</span>
                                                <span className='text-[13px]'>+91 9871729030</span>
                                            </p>    
                                        </address>
                                    </div>
                                    <div className="col-lg-12 flex gap-2">
                                        <p>
                                            <FaClock/>
                                        </p>
                                        <address>
                                            <span className='h6'>Working Time :</span>
                                            <p className='text-[12px]'>Mon - Sat, 10:00 am to 6:00 pm</p>
                                        </address>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg">
                                <form action="">
                                    <div className="form-group mb-3">
                                        <label htmlFor="name" className='h6'>Your name</label>
                                        <input type="text" name="" id="" className='form-control' placeholder='your name' />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="name" className='h6'>Email</label>
                                        <input type="text" name="" id="" className='form-control' placeholder='your email' />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="name" className='h6'>Subject</label>
                                        <input type="text" name="" id="" className='form-control' placeholder='Subject' />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="name" className='h6'>Message</label>
                                        <textarea rows={5} className='form-control' placeholder='message' />
                                    </div>
                                    <div className="form-group mb-3">
                                        <button className='btn bg-[#b88e2f] hover:bg-[#d1ab51] text-light fw-bold rounded-none w-25'>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contact