import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useUserContext } from '../../context';

const UserDetails = () => {
  const { data ,UpdateUser} = useUserContext()
  const [fname, setFname] = useState(true)
  const [lname, setLname] = useState(true)
  const [email, setEmail] = useState(true)
 
  const [value,setValue] = useState({
    fname:"",
    lname:"",
    email:"",
  })

  const changeData = {
    fname:value.fname ? value.fname : data?.fname,
    lname:value.lname ? value.lname : data?.lname,
    email:value.email ? value.email : data?.email
  }

 const handleClick = ()=>{
  UpdateUser(changeData);
 }

  

  return (
    <>
      <div className="conatainer-fluid" >
        <div className="container-sm">
          <div className="row gap-3">
            <div className="col-lg border-1 border-gray-200 relative p-2 overflow-hidden rounded-lg">
              <img src="https://shorturl.at/sOPW3" alt="" className='object-cover' />
              <h3 className='fw-bold h2 absolute z-50 left-0 bottom-24 text-light bg-[#ffc107bd] ps-6 w-100' >Track<br/> Orders</h3>
            </div>
            <div className="col-lg border-1 border-gray-200 relative p-2 overflow-hidden rounded-lg">
              <img src="https://shorturl.at/gAGT3" alt="" className='object-cover' />
              <h3 className='fw-bold h2 absolute z-50 left-0 bottom-24 text-light bg-[#b7100dbe] ps-6 w-100 ' >Billing<br/> Address</h3>
            </div>
          </div>
        </div>
        <div className="container-sm">
          <h3 className='fw-bold fs-4 py-2'>Accounts Details</h3>
          <form action="">
            <table className="table border-none">
              <thead>
              <tr>
                <th scope="col" className='col-3'>First Name</th>
                <td>
                  <input type='text' className='col-5'  defaultValue={data?.firstName} onChange={(e)=>setValue({...value,fname:e.target.value})} />
                  {
                    fname ?
                      <Link key="edit" className='text-primary mx-2' onClick={() => setFname(false)} >Edit</Link>
                      : 
                      <>
                        <Link key="cancel" className='text-primary' onClick={() => setFname(true)} >Canel</Link>
                        <Link key="save" className='text-primary px-2' onClick={() => {setFname(true);handleClick()}} >Save</Link>
                      </>
                  }
                </td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="col" className='col-3'>Last Name</th>
                <td><input type='text' className='col-5'  defaultValue={data?.lastName} onChange={(e)=>setValue({...value,lname:e.target.value})} /> {
                  lname ?
                    <Link key="edit" className='text-primary mx-2' onClick={() => setLname(false)} >Edit</Link>
                    : <>
                      <Link key="cancel" className='text-primary' onClick={() => setLname(true)} >Canel</Link>
                      <Link key="save" className='text-primary px-2' onClick={() => {setLname(true);handleClick()}} >Save</Link>
                    </>
                }</td>
              </tr>
              <tr>
                <th scope="col" className='col-3'>Email</th>
                <td><input type='text' className='col-5'  defaultValue={changeData.email}  onChange={(e)=>setValue({...value,email:e.target.value})}/>
                  {
                    email ?
                      <Link key="edit" className='text-primary mx-2' onClick={() => setEmail(false)} >Edit</Link>
                      : <>
                        <Link key="cancel" className='text-primary' onClick={() => setEmail(true)} >Canel</Link>
                        <Link key="save" className='text-primary px-2' onClick={() => {setEmail(true);handleClick()}} >Verify</Link>
                      </>
                  }
                </td>
              </tr>
              <tr>
                <th scope="col" className='col-3'>Mobile</th>
                <td><input type='text' className='col-5' defaultValue={data.mobile} />
                </td>
              </tr>
              </tbody>
            </table>
          </form>
          <p className='py-3'>
            <Link className='fs-6 fw-bold text-primary'>Change password</Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default UserDetails