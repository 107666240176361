
import { useContext } from "react";
import { AuthContext } from "./authContext/AuthContext";
import { ProductContext } from "./productContext/ProductContext";
import { CartContext } from "./cartContext/CartContext";
import { userContext } from "./userContext/UserContext";

export { default as AuthContextProvider } from './authContext/AuthContext';
export {default as ProductContextProvider} from './productContext/ProductContext';
export {default as CartContextProvider} from './cartContext/CartContext';
export {default as UserContextProvider} from './userContext/UserContext';

export const useAuthContext = () => useContext(AuthContext);
export const useProductContext = () => useContext(ProductContext);
export const useCartContext = () => useContext(CartContext);
export const useUserContext = () => useContext(userContext);
