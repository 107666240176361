import React from 'react'
import all_loader_img from '../../assets/Rounded blocks.gif'
const Allloader = () => {
  return (
    <>
      <div className='flex justify-center'>
        <img src={all_loader_img} alt="" />
      </div>
    </>
  )
}

export default Allloader