import React from 'react';
import { useProductContext } from '../../context';
import { Link } from 'react-router-dom';

const CategoryCard = () => {
  const { category } = useProductContext();

  // Shuffle function to shuffle the array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Shuffle the category array
  const shuffledCategory = shuffleArray([...category]);

  // Take the first 3 elements from the shuffled array
  const randomCategory = shuffledCategory.slice(0, 3);

  return (
    <div className="row pt-8">
      {randomCategory.map((item, idx) => (
        <div key={idx} className="col-lg-4">
          <div className="col gap-3">
           <Link to={`/shop/${item.category_name}`} className='d-flex items-center justify-center flex-col'>
            <div className="h-[60vh] w-[90%]">
              <img src={item.image_url} className='size-full'  alt="" />
            </div>
            <h3 className="h3 text-center">{item.category_name}</h3>
           </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryCard;
