import React from 'react'
import { useFormik } from 'formik'
import * as yup from "yup"
import { useUserContext } from '../../context'
import { useLocation } from 'react-router-dom'

const AddressForm = ({ setAccordion }) => {
    const { createInformation } = useUserContext()
    const location = useLocation()
    const initialstate = {
        addressLine1: "",
        addressLine2: "",
        Landmark: "",
        city: "",
        state: "",
        pincode: "",
        longitude: "",
        address_type:""
    }

    const validation = yup.object({
        addressLine1: yup.string().required("Address Line 1 Required"),
        Landmark: yup.string().min(2).required("Landmark is required"),
        city: yup.string().min(2).required("city is required"),
        state: yup.string().min(2).required("state is required"),
        pincode: yup.number().min(6).required("pincode is required"),
        address_type: yup.string().required("select address type is required"),


    })

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: initialstate,
        validationSchema: validation,
        onSubmit: (value) => {
            createInformation(value);
            setAccordion(false)
            resetForm()
        }

    })
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='form-group mb-3 row'>
                    <div className='col-lg'>
                        <label htmlFor="recieverName">addressLine1</label>
                        <input type="text" className='form-control rounded-0' name="addressLine1" value={values.addressLine1} onChange={handleChange} onBlur={handleBlur} />
                        {touched.addressLine1 && errors.addressLine1 ? <p className='text-red-500' >{errors.addressLine1}</p> : null}
                    </div>
                    <div className='col-lg'>
                        <label htmlFor="recieverName">addressLine2</label>
                        <input type="text" className='form-control rounded-0' name="addressLine2" value={values.addressLine2} onChange={handleChange} onBlur={handleBlur} />
                        {touched.addressLine2 && errors.addressLine2 ? <p className='text-red-500' >{errors.addressLine2}</p> : null}
                    </div>
                </div>
                <div className='form-group mb-3 row'>
                    <div className='col-lg'>
                        <label htmlFor="recieverName">Landmark</label>
                        <input type="text" className='form-control rounded-0' name="Landmark" value={values.Landmark} onChange={handleChange} onBlur={handleBlur} />
                        {touched.Landmark && errors.Landmark ? <p className='text-red-500' >{errors.Landmark}</p> : null}
                    </div>
                    <div className='col-lg'>
                        <label htmlFor="recieverName">longitude</label>
                        <input type="text" className='form-control rounded-0' name="longitude" maxLength={10} value={values.longitude} onChange={handleChange} onBlur={handleBlur} />

                    </div>

                </div>
                <div className='form-group mb-3 row'>
                    <div className='col-lg'>
                        <label htmlFor="recieverName">city</label>
                        <input type="text" className='form-control rounded-0' name="city" value={values.city} onChange={handleChange} onBlur={handleBlur} />
                        {touched.city && errors.city ? <p className='text-red-500' >{errors.city}</p> : null}
                    </div>
                    <div className='col-lg'>
                        <label htmlFor="recieverName">state</label>
                        <input type="text" className='form-control rounded-0'  name="state" value={values.state} onChange={handleChange} onBlur={handleBlur} />
                        {touched.state && errors.state ? <p className='text-red-500' >{errors.state}</p> : null}
                    </div> <div className='col-lg'>
                        <label htmlFor="recieverName">pincode</label>
                        <input type="text" className='form-control rounded-0' maxLength={6} name="pincode" value={values.pincode} onChange={handleChange} onBlur={handleBlur} />
                        {touched.pincode && errors.pincode ? <p className='text-red-500' >{errors.pincode}</p> : null}
                    </div>
                    
                </div>
                

                <div className='form-group mb-3 row'>
                    <div className='col-lg flex items-center gap-6'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="address_type" id="flexRadioDefault1" value="Home" checked={values.address_type === "Home"} onChange={handleChange} onBlur={handleBlur} />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Home
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="address_type" id="flexRadioDefault2" value="Work" checked={values.address_type === "Work"} onChange={handleChange} onBlur={handleBlur} />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Work
                            </label>
                        </div>
                        {touched.address_type && errors.address_type ? <p className='text-red-500' >{errors.address_type}</p> : null}
                    </div>
                </div>
                <div className='form-group mb-3 flex items-center gap-3'>
                    <button className='btn btn-outline-primary w-25 ' type='submit' >Save</button>

                    <button className='btn btn-outline-primary w-25' onClick={() => setAccordion(false)}  >Cancel</button>

                </div>

            </form>
        </>
    )
}

export default AddressForm