import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CreateUserInformation, DeleteUserInformation, GetUserInformation, GetUserUrl, UpdateUserUrl } from "../../apis/ApiUrl";
import { useAuthContext } from "..";

export const userContext = createContext()


const UserContextProvider  = ({children})=>{
    const token = localStorage.getItem('token')
    const {change} = useAuthContext()

    const [data,setData] = useState([])
    const [information,setInformation] = useState([])
   
    const [info,setInfo] = useState({
        info_id:""
      })
      const selectedInfo = {
         info_id:info?.info_id !== "" ? info?.info_id : information?.info_id
      }

      const SelectedInformation = information?.filter((item)=>item.info_id === selectedInfo?.info_id)[0]
      

    const getdata = async() => {
        try{
            const res = await axios.get(GetUserUrl,{headers:{
                Authorization: `espsadmin ${token}`
            }})
            
            setData(JSON.parse(res.data.data)[0])

        }catch(err){
            console.log("data not found internal error")
        }
    }

    const UpdateUser = async(data) => {
        try{
            const res = await axios.patch(UpdateUserUrl,data,{
                headers:{
                    Authorization:token
                }
            })
            toast.success(res.data.message)
        }catch(err){
            toast.error("somthing went wrong")
        }
    }

    const getUserInformation = async() => {
        try{
            const res = await axios.get(GetUserInformation,{headers:{Authorization:`espsadmin ${token}`}})
            
            setInformation(JSON.parse(res.data.data))
        }catch(err){
            toast.error("somthing went wrong")
        }
    }

    const createInformation = async(data) => {
        try{
            const res = await axios.post(CreateUserInformation,data,{headers:{Authorization:`espsadmin ${token}`}})
            toast.success(res.data.message)
            getUserInformation()
        }catch(err){
            toast.error("Somthing went wrong");
        }
    }

    const DeleteInformation = async(id) => {
        console.log(id)
        try{
            const res = await axios.delete(`${DeleteUserInformation}${id}`,{headers:{
                Authorization:`espsadmin ${token}`
            }})
            console.log(res)
            toast.success(res.data.message)
            getUserInformation()
        }catch(err){
            toast.error("Somthing went wrong")
        }
    }
    

    useEffect(()=>{
        if(token){
            getdata()
            getUserInformation()
        }
    },[change])
    return (
        <userContext.Provider value={{data,UpdateUser,createInformation,DeleteInformation,information,setInfo,info,SelectedInformation}}>
            {children}
        </userContext.Provider>
    )
}

export default UserContextProvider