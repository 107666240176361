import React from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import {  useCartContext } from '../context';
import cartImage from "../assets/EmptyCart.gif"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CheckOut } from '../components';

const Cart = () => {
  const token = localStorage.getItem("token")
  const {cartItems} = useCartContext()
  const {ADDQTY, REMOVECART} = useCartContext();
  
  console.log(cartItems)


  const handleCheckout = async () => {
    const notify = toast.loading("Please wait...")
    try {
      const stripe = await loadStripe('pk_test_51Ou7BsSCKTxLuWaq7mfMAXjIcD1HihLyGQRao79ChFJqDuYgfeU12LknQpXrKzJR8ToD9gFCLBK9FKB53eYkPNjd00349UKE8w');
      
      const response = await axios.post('https://api.digiuncle.co.in/create-checkout-session', cartItems, {
        headers : {Authorization:`espsadmin ${token}`}
      });
      toast.update(notify, {  type: "success", isLoading: false,autoClose: 1000, });
      const sessionId = response.data.sessionId;
      console.log(response)
      
      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (result.error) {
        console.error("Error redirecting to checkout:", result.error);
      }
    } catch (error) {
      toast.update(notify, { render: "somthing went wrong", type: "error", isLoading: false,autoClose: 1000, });
      console.error("Error handling checkout:", error);
    }
  };

  return cartItems.length === 0 ? <>
  <div className='h-[47vh] w-[100wh] flex items-center justify-center '  >
   
    <img src={cartImage}  />
    
  </div>
  <div className='text-center py-2' >

  <Link to="/" className='text-blue-600' >Back to shoping</Link>
  </div>
  </> :(
    <div className='container-fluid'>
      <div className="container py-4">
        <div className="row">
          <div className="col-lg">
            <div className="col flex justify-between py-3">
              <span className='fs-6 text-secondary fw-bold'>Continue shopping</span>
              <span>{cartItems ? cartItems?.length : 0} Items</span>
            </div>
            {cartItems && cartItems.map((item, idx) => (
              <div key={idx} className='row py-2 mb-3 border-1 border-gray-200 rounded'>
                <div className="col-lg">
                  <h4>{item?.product_name?.slice(0,25)}...</h4>
                  <div className="col flex gap-3 mt-3">
                    <div className="img-box">
                      <img src={item?.product_image} alt="" className='size-24' />
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <span className='text-success'>In stock</span>
                          <select className='form-select w-auto'  onChange={(e)=>ADDQTY(item._id,e.target.value)}>
                            <option disabled value="qty" >Qty.</option>
                            <option value="1"  >1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </select>
                          <span className='text-secondary text-[12px]'>{item?.product_qty} items</span>
                      </div>
                      <div className="col-lg-3">
                        <span>Rs. {item?.product_price}</span>
                        <button className='text-red-500' onClick={()=>REMOVECART(item._id)} > remove </button>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg">
            <div className='row justify-center items-center'>
              <div className="col-lg-8 py-3">
                <form action="" className='flex items-center mb-3'>
                  <input type="text" className='form-control py-2 rounded-0' placeholder='apply coupon code' />
                  <button className='btn-main rounded-0'>Apply</button>
                </form>
                <button className='btn-main w-100' data-bs-toggle="modal" data-bs-target="#exampleModal">Checkout</button>
                <CheckOut />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Cart;
