import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useAuthContext, useUserContext } from '../context'

const Profile = () => {
    const { data } = useUserContext()
    const { logoutHandler } = useAuthContext();
    const nav = [
        {
            name:"My Profile",
            path:"/profile"
        },
        {
            name:"Orders",
            path:"myorders"
        },
        {
            name:"Saved Address",
            path:"saveed-address"
        },
        {
            name:"Coupons",
            path:"mycoupons"
        },
    ]
  


    return (
        <>
            <div className='container-fluid bg-[#fff] py-6'>
                <div className="container-sm">
                    <div className="row">
                        <div className="col-lg-3 py-2 border">
                            <div className="col-lg flex flex-col justify-center items-center gap-3">
                                <img src="https://xsgames.co/randomusers/avatar.php?g=male" alt="" className="img-fluid size-32 rounded-circle" />
                                <h4 className='fs-4 font-semibold'>{data.fname} {data.lname}</h4>
                                <p>{data.email}</p>
                            </div>
                            <p className='hr my-6'></p>
                            <ul className='nav flex-column'>
                            {nav.map((item,index) => {
                                return (
                                    <li key={index} className='nav-item'>
                                        <Link to={item.path} className='nav-link text-dark'>{item.name}</Link>
                                    </li>
                                )
                            })}
                                <li className='nav-item'>
                                    <Link onClick={logoutHandler} className='nav-link text-dark'>Logout</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg">
                           <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile