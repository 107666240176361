import React from 'react'
import { slides } from '../../backend/Slides'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import { Link } from 'react-router-dom';

const Banner = () => {
  let settings ={
    dots : true,
    Infinite : true,
    speed : 500,
    slidesToShow : 1,
    slidesToScroll : 1,
    autoplay : true,
  }  
  return (
    <>
        <div className="conyainer-fluid my-3">
        <Carousel width="100%" axis='horizontal' showThumbs={false} infiniteLoop={true} autoPlay={true}>
          {slides?.map((slides, idx) => (
            <div key={idx} className="container-fluid flex justify-end items-center lg:h-[60vh]">
                 <div className="container">
                    <Link><img src={slides.image} className='img-fluid' alt="" /></Link>
                  </div> 
            </div>
          ))}
        </Carousel>
        </div>
    </>
  )
}

export default Banner