import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FaListUl, FaFilter } from "react-icons/fa";
import { IoGridOutline } from "react-icons/io5";
import AnimatedCollapsibles from "../components/pagination/AnimatedCollapsibles";
import Pagination from "../components/pagination/Pagination";
import { useProductContext } from "../context";

const Shop = () => {
  const { product } = useProductContext();
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const {category} = useParams();
  const [filteredProducts, setFilteredProducts] = useState([]);

  const productsPerPage = 20;

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Filter products based on the clicked category
    if (category) {
      const filtered = product.filter((prod) => prod.category === category);
      console.log("this is fil", filtered)
      setFilteredProducts(filtered);
      setCurrentPage(1); // Reset pagination to first page
    } else {
      // If no category is clicked, show all products
      setFilteredProducts(product);
    }
  }, [category, product]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const showingFrom = indexOfFirstProduct + 1;

  return (
    <>
      <div className="container-fluid py-2">
        <div className="container-sm">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-between align-items-center py-2">
              <div className="d-flex gap-2 align-items-center">
                <button
                  onClick={toggleFilter}
                  className="btn btn-outline-dark d-flex align-items-center gap-3"
                >
                  <FaFilter />
                </button>
                <h4 className="fs-6">Showing Results</h4>
                <span className="font-semibold text-[13px] text-secondary">
                  ({showingFrom} - {indexOfLastProduct} of{" "}
                  {filteredProducts?.length} products)
                </span>
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className="d-flex align-items-center">
                  <button className="btn">
                    <FaListUl />
                  </button>
                  <button className="btn">
                    <IoGridOutline />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className={openFilter ? "col-lg-3 d-block transition-all" : "w-[0px] hidden overflow-hidden transition-all"}>
            <AnimatedCollapsibles />
          </div>
          <div className="col-lg">
            <div className="row py-8 justify-center">
              {currentProducts?.map((product, index) =>
                {
                  const imagees = product?.image.split(",")
                  let urlWithoutBrackets = imagees[0].replace(/^\[|\]$/g, ''); 
                  const image = JSON.parse(urlWithoutBrackets)
                  return (
                  <div key={index} className={openFilter ? "col-lg-3 hover:shadow-xl mb-3 col-6" : `col-lg-2 hover:shadow-md mb-3 col-6 col-md-3`}>
                      <div className="card ms-2 rounded-none border-none">
                        <Link
                          to={`/product/${product.product_id}/`}
                          className="nav-link flex justify-center"
                        >
                          <img
                            src={image}
                            alt=""
                            className="img-fluid size-44"
                          />
                        </Link>
                        <div className="card-body d-flex flex-column items-center py-2">
                          <Link
                            to={`/product/${product.product_id}/`}
                            className="text-secondary"
                          >
                            {product.name?.slice(0, 25)}
                          </Link>
                          <div className="lg:flex items-end">
                              {product.MRP_price ? (
                                <p className="flex gap-3 items-center">
                                  <h6 className="text-[12px] lg:text-[15px] font-bold text-dark">
                                    Rs. {product.selling_price}
                                  </h6>
                                  <del className="text-[10px] text-gray-400 font-semibold lg:text-[13px]">
                                    Rs. {product.MRP_price}
                                  </del>
                                </p>
                              ) : (
                                <p className="font-bold text-dark">
                                  Rs. {product.selling_price}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                  </div>
                )})}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <Pagination
            productsPerPage={productsPerPage}
            totalProducts={filteredProducts?.length}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </> 
  );
};

export default Shop;
