import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link component from react-router-dom
import { FaChevronDown } from "react-icons/fa";

const OrderList = () => {
    const [accordian, setAccordian] = useState(false); // Initialize accordian state as false

    const toggleAccordian = () => {
        setAccordian(prevState => !prevState); // Toggle accordian state
    };

    return (
        <>
            <div className="row justify-center py-3">
                <div className="col-lg-10">
                    <div className="col flex items-center gap-3">
                        <p className='text-bold h4'>Recent Orders</p>
                        <Link to="#" className="text-info h6">View all</Link> {/* Use Link component with "to" prop */}
                    </div>
                </div>
            </div>
            <div className='row justify-center py-3'>
                <div className="col-lg">
                    <div onClick={toggleAccordian}  className="header flex items-center justify-between cursor-pointer bg-gray-300 px-3 py-3">
                        <Link to="#" className="header"> {/* Use Link component with "to" prop */}
                            Product name
                        </Link>
                        <p>
                            <FaChevronDown />
                        </p>
                    </div>
                    <div className={accordian ? 'accordian-body h-auto overflow-hidden transition-all' : 'accordian-body h-0 overflow-hidden transition-all'}>
                        <div className='col-lg bg-success'>
                            <h3 className='fs-4'>Track your order</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderList;
