import React from 'react'
import { Banner, CategoryCard, ProductCard } from '../components'
import { Link } from 'react-router-dom'
import { useProductContext } from '../context'

const Home = () => {
  const {product} = useProductContext();
  return (
    <>
      <Banner />
     <div className='container-fluid'>
      <div className="container-sm py-6">
        <h3 className='text-center fw-bold text-[#333333] display-6 pb-2 pt-8'>Browse the Range</h3>
        <CategoryCard />
      </div>
      <div className='container-sm'>
        <h2 className='display-5 text-center fw-bold py-3'>Popular Products</h2>
      </div>
      <ProductCard data={product} />
      <p className='flex justify-center py-3'><Link to="/shop"className='btn-main' >Show More</Link></p>
     </div>
    </>
  )
}

export default Home