import React from 'react'
import loader_img from '../../assets/Animation - 1709632992877.gif'
import logo_img from '../../assets/PNG/flogo.png'

const Loader = () => {
  return (
    <>
        <div className="container-fluid h-[100vh] bg-[#fff3e3] flex flex-col justify-center items-center">
            <img src={loader_img} alt="" />
            <span className='animate-bounce'><img src={logo_img} alt="" className='w-44' /></span>
        </div>
    </>
  )
}

export default Loader