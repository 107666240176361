import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import ProductCard from './ProductCard';
import { loadStripe } from '@stripe/stripe-js';
import { useCartContext, useProductContext } from '../../context';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Reviews } from '../../pages';



const ProductDetail = () => {
    const token = localStorage.getItem('token')
    const { ADDTOCART } = useCartContext()
    const { product } = useProductContext()
    const [renderImage, setRenderImage] = useState(null)
    const { id } = useParams();
    const navigate = useNavigate()



    const selectedProduct = product.filter(product => product.product_id === id);
    const filterByCategory = product.filter(product => product?.category === selectedProduct[0]?.category);


    const discountPrice = (selectedProduct[0]?.MRP_price - selectedProduct[0]?.selling_price) * 100

    const parcentage = discountPrice / selectedProduct[0]?.MRP_price


    const handleCheckout = async (data, image) => {

        const notify = toast.loading("Please wait...")
        const cartItems = {
            product_qty: 1,
            product_price: data.selling_price,
            product_color: data.Product_color,
            product_size: "nothing",
            product_name: data.name,
            product_image: image
        }
        try {
            const stripe = await loadStripe('pk_test_51Ou7BsSCKTxLuWaq7mfMAXjIcD1HihLyGQRao79ChFJqDuYgfeU12LknQpXrKzJR8ToD9gFCLBK9FKB53eYkPNjd00349UKE8w');

            const response = await axios.post('https://api.digiuncle.co.in/create-checkout-session', [cartItems], { headers: { Authorization: `espsadmin ${token}` } });
            toast.update(notify, {  type: "success", isLoading: false,autoClose: 1000, });
            const sessionId = response.data.sessionId;

            const result = await stripe.redirectToCheckout({
                sessionId: sessionId,
            });

            if (result.error) {
                console.error("Error redirecting to checkout:", result.error);
            }
        } catch (error) {
            console.error("Error handling checkout:", error);
            toast.update(notify, { render: "somthing went wrong", type: "error", isLoading: false,autoClose: 1000, });
        }
    };

    useEffect(()=>{
        setRenderImage(null)
    },[id])
   


    if (!selectedProduct) {
        return <div>Product not found</div>;
    }



    return (
        <>
            <div>

                {
                    selectedProduct ? selectedProduct?.map((item, index) => {
                        const imagees = item?.image.split(",")

                        let urlWithoutBrackets = imagees.map((item) => item.replace(/^\[|\]$/g, ''));

                        const image = JSON.parse(urlWithoutBrackets[0])

                        return (
                            <div key={index}>
                                <div className='container-fluid'>
                                    <div className='container-sm'>
                                        <div className='row'>
                                            <div className="col-lg py-4">
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <div className='col'>
                                                            {urlWithoutBrackets ? urlWithoutBrackets.map((item, index) => {
                                                                const data = item.replace(/"/g, '')
                                                                return (
                                                                    <Link key={index} onClick={() => setRenderImage(data)} ><img src={data} alt="" className='size-24 img-thumbnail mb-2' /></Link>
                                                                )
                                                            }) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <img src={renderImage ? renderImage : image} alt="" className='size-96' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg py-4">
                                                <span className='h6 text-secondary'>{item.brand?.slice(0, 10)}</span>
                                                <h4 className='h4'>{item.name}</h4>
                                                <ReactStars
                                                    count={item.rating}
                                                    size={24}
                                                    activeColor="#5c5470"
                                                    isHalf={true}
                                                    value={item.rating}
                                                    edit={false}
                                                />
                                                {item.selling_price ? (
                                                    <p className='flex gap-3'>
                                                        <span className='h5 text-success'>Rs. {item.selling_price}</span>
                                                        <span className='line-through'>Rs. {item.MRP_price}</span>
                                                        <span className='badge text-bg-success pt-2' > {parcentage.toFixed()} % Off</span>
                                                    </p>

                                                ) : (
                                                    <p>&#8377; {item.MRP_price}</p>
                                                )}
                                                <div>
                                                    {item.IN_stock > 10 ? (
                                                        <button className='btn bg-success-subtle text-success fw-bold'>In Stock</button>
                                                    ) : (
                                                        <button className='btn bg-danger-subtle text-danger fw-bold'>Out of Stock</button>
                                                    )}
                                                </div>
                                                <div className='lg:flex md:flex items-center gap-2 py-3'>
                                                    <button className='btn bg-[#b88e2f] hover:bg-[#c9a44e] text-white h5 fw-bold w-100 w-lg-25 py-3 rounded-none' onClick={() => { token ? handleCheckout(item, image) : navigate('/login') }} >Buy</button>
                                                    <button onClick={() => { token ? ADDTOCART(item, image) : navigate('/login') }} className='btn btn-outline-warning rounded-none py-3 h5 fw-bold w-100 w-lg-25'>Add to Cart</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid">
                                    <div className="container-sm">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className='text-2xl fw-bold'>Product description :</h2>
                                                <p>{item.description}</p>
                                            </div>
                                            <div className="col-lg-4 py-3">
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th scope='col'>Material</th>
                                                            <td>{item.material}</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope='col'>Brand</th>
                                                            <td>{item.brand}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope='col'>Color</th>
                                                            <td>{item.Product_color}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope='col'>Category</th>
                                                            <td>{item.category}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope='col'>Weight</th>
                                                            <td>{item.weight}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='container-fluid'>
                                    <div className='container-sm'>
                                        <div className='flex items-center justify-between'>
                                            <h2 className='h4 font-semibold text-[#352f44] uppercase'>similar products..</h2>
                                            <Link to={`/shop/${item.category}`} className='text-md font-bold text-blue-700'>See all...</Link>
                                        </div>
                                        <ProductCard data={filterByCategory}  />
                                    </div>
                                </div>
                            </div>
                        )
                    }) : null
                }
            </div>
            <Reviews />
        </>
    );
};

export default ProductDetail;
