import { AddressBook, Cart, CouponsBook, OrderList, Orders, Profile } from "../pages";

const privateRoutes = [
    {
        path: "/cart",
        element: <Cart />,
    },
    {
        path: "/orders",
        element: <Orders />,
    },
    {
        path: '/profile',
        element: <Profile />
    },
    
];

const userProfileRoutes = [
    {
        path: "myorders",
        element: <OrderList />
    },
    {
        path: "saveed-address",
        element: <AddressBook />
    },
    {
        path: "mycoupons",
        element: <CouponsBook />
    },
]

export { privateRoutes, userProfileRoutes };