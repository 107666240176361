import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo_img from '../assets/PNG/logo.png'
import { FaXTwitter, FaGooglePlusG, FaLinkedin, FaPinterest } from "react-icons/fa6";
import { useProductContext } from '../context';

const Footer = () => {
    const {category} = useProductContext();
    return (
        <>
            <footer className='container-fluid py-4 bg-[#f2f2f2]'>
                <div className='container-sm'>
                    <div className="row gap-3">
                        <div className="col-lg-4 ">
                            <div className='navbar-brand mb-4'>
                                <Link><img src={logo_img} alt="" width={150} /></Link>
                            </div>
                            <Link href="tomail:info@spsolutions.org.nz" className='text-gray-500 block text-sm' >info@spsolutions.org.nz</Link>
                            <Link href="https://www.google.com/maps/@28.3961028,77.2668084,16z?entry=ttu" className='text-gray-500 text-sm' >Achiver center point mall, Sector-49, Faridabad, Haryana</Link>

                        </div>
                        <div className="col-lg">
                            <div className="row py-3">
                                <div className="col-6 col-lg">
                                    <h6 className='text-[#222] font-bold'>QUICK LINKS</h6>
                                    <ul className='nav flex-column'>
                                        <li>
                                            <Link to="about" className='text-[15px] font-semibold text-secondary'>About us</Link>
                                        </li>
                                        <li>
                                            <Link to='contact' className='text-[15px] font-semibold text-secondary'>Contact us</Link>
                                        </li>
                                        <li>
                                            <Link to="shop" className='text-[15px] font-semibold text-secondary'>Shop</Link>
                                        </li>
                                        <li>
                                            <Link className='text-[15px] font-semibold text-secondary'>Help & Support</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 col-lg">
                                    <h6 className='text-[#222] font-bold'>ONLINE SHOPPING</h6>
                                    <ul className='nav flex-column'>
                                        {category.map((item, idx) => (
                                            <li key={idx}>
                                                <Link to={`/shop/${item.category_name}`} className='text-[15px] font-semibold text-secondary'>{item.category_name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-sm">
                    <div className="row items-center">
                        <div className="col-lg-8">
                            <ul className='nav justify-evenly'>
                                <li className='nav-item'>
                                    <Link className='nav-link flex items-center text-[#222] gap-2'><FaXTwitter /> Twitter</Link>
                                </li>
                                <li className='nav-item'>
                                    <Link className='nav-link flex items-center text-[#222] gap-2'><FaGooglePlusG />Google+</Link>
                                </li>
                                <li className='nav-item'>
                                    <Link className='nav-link flex items-center text-[#222] gap-2'><FaLinkedin />LinkedIn</Link>
                                </li>
                                <li className='nav-item'>
                                    <Link className='nav-link flex items-center text-[#222] gap-2'><FaPinterest />Pinterest</Link>
                                </li>
                            </ul>
                        </div>
                        <div className='col-lg'>
                            <p className='text-[15px] text-dark'>&copy; 2023 - {(() => new Date().getFullYear())()} <span className='font-bold text-[#222]'>digiuncle.co.in</span></p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer