import { ProductDetail } from "../components";
import { About, Contact, Login, Shop, Signup } from "../pages";

const authRoutes = [
    {
        path : 'login',
        element: <Login/>,
    },
    {
        path : "signup",
        element : <Signup />
    },
];

const contentRoutes = [
    {
        path : "/product/:id",
        element : <ProductDetail />
    },
    {
        path : "/shop",
        element : <Shop />
    },
    {
        path : "/shop/:category",
        element : <Shop />
    },
    {
        path : "/contact",
        element : <Contact />
    },
    {
        path : "/about",
        element : <About />
    }
]


export {authRoutes, contentRoutes}