import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Addqty, AddtoCArt, RemoveCArt } from '../../apis/ApiUrl';
import { useAuthContext } from '..';

export const CartContext = createContext();

const CartContextProvider = ({ children }) => {
  const {token } = useAuthContext()
  const [cartItems, setCartItems] = useState([]);

  const fetchCartItems = async () => {
    try {
      const response = await axios.get('https://api.digiuncle.co.in/cart/get-cart',{headers:{Authorization:`espsadmin ${token}`}});
     
      setCartItems(JSON.parse(response.data.data));
    } catch (err) {
      console.error("Error fetching cart items:", err);
    }
  };

  

  const ADDTOCART = async (data, image) => {
    const id = toast.loading("Please wait...")
    const newdata = {
      product_qty: 1,
      product_price: data.selling_price,
      product_color: data.Product_color,
      product_size: "nothing",
      product_name: data.name,
      product_image: image
    }
    
    try {
      const res = await axios.post(AddtoCArt,newdata,{headers:{Authorization:`espsadmin ${token}`}})
      await fetchCartItems()
      toast.update(id, { render: res.data.message, type: "success", isLoading: false,autoClose: 1000, });
      
    } catch (err) {
      toast.update(id, { render: "somthing went wrong", type: "errors", isLoading: false,autoClose: 1000, })
    }
  }

  const ADDQTY = async (id, qty) => {
    const notify = toast.loading("Please wait...")
    try {
      const res = await axios.post(`${Addqty}${id}`,{qty},{headers:{Authorization:`espsadmin ${token}`}})
      toast.update(notify, { render: res.data.message, type: "success", isLoading: false,autoClose: 1000, });
      fetchCartItems()
    } catch (err) {
      toast.update(notify, { render: "somthing went wrong", type: "error", isLoading: false,autoClose: 1000, });
      
    }
  }

  const REMOVECART = async (id) => {
    const notify = toast.loading("Please wait...")
    try{
      const res = await axios.delete(`${RemoveCArt}${id}`,{headers:{Authorization:`espsadmin ${token}`}})
      toast.update(notify, { render: res.data.message, type: "success", isLoading: false,autoClose: 1000, });
     fetchCartItems()
    }catch(err){
      toast.update(notify, { render: "somthing went wrong", type: "error", isLoading: false,autoClose: 1000, });
    }
  }


  useEffect(()=>{
    if(token){
      fetchCartItems()
    }
  },[])


  return (
    <CartContext.Provider
      value={{
         fetchCartItems, ADDQTY, ADDTOCART, REMOVECART,cartItems
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
