import carousal_img1 from '../assets/banner/1-min.png';
import carousal_img2 from '../assets/banner/2-min.png';
import carousal_img3 from '../assets/banner/3-min.png';
import carousal_img4 from '../assets/banner/4-min.png';


const slides = [
    {
        id : 1,
        image : carousal_img1,
        title1 : "Home Decor",
        title2 : "Transform Your Space!",
        description : "Elevate your home decor with our exclusive collection. Perfect for every room, our products are designed to bring warmth, style, and sophistication to your living space. Explore now and let your creativity shine!"
      },
      {
        id : 2,
        image : carousal_img2,
        title1 : "New Arrival",
        title2 : "Our Exclusive Collection For Wardrobe ",
        description : "Discover the latest trends and elevate your style with our exclusive collection. Perfect for every occasion, our clothing is designed to make you feel confident and stylish. Don't miss out on this limited-time offer. Shop now and transform your wardrobe"
      },
      {
        id : 3,
        image : carousal_img4,
        title1 : "Minimalist Living Room",
        title2 : "Embrace Minimalism in Your Living Room",
        description : "Experience the serene elegance of minimalism with our exclusive living room collection. Designed to bring a sense of calm and sophistication to your space, our products are perfect for those seeking a minimalist aesthetic. Discover the perfect blend of functionality and style. Shop now and transform your living room into a sanctuary of minimalist living"

      },
      {
        id : 4,
        image : carousal_img3,
        title1 : "Cozy Bedroom",
        title2 : "Create a Cozy Haven in Your Bedroom",
        description : "Transform your bedroom into a cozy retreat with our comfort-focused decor. From plush bedding to warm-toned wall art, our collection is designed to create a welcoming and inviting space. Explore now and let our products help you create the perfect bedroom environment for relaxation and res",
      },
      
]

export {slides}