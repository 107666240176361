import React from 'react'
import {Link } from 'react-router-dom'
import { BsGraphUpArrow } from "react-icons/bs";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { FaStar } from "react-icons/fa";




const About = () => {
  return (
    <>
      <div className="row items-center justify-center min-h-[40vh] bg-gradient-golden">
        <div className="container">
          <div className="row min-h-[40vh] items-center">
            <div className="col-lg py-4">
              <h1 className='display-4 mb-4 text-center font-bold text-[#fff3e3]'>About us</h1>
            </div>
          </div>
        </div>
      </div>
    <div className="container-fluid">
      <div className="container">
        <div className="row py-3">
          <div className="col-lg">
            <div className='row gap-2 items-center'>
              <div className="col-lg-5 flex flex-col gap-2 py-3 bg-[#fff3e3]">
                <BsGraphUpArrow size={30} />
                <h2 className='h4 text-[#b88e2f] font-bold'>Our Approach</h2>
                <p className='text-secondary'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                  Voluptatem qui quas, eveniet et natus nam sequi harum optio incidunt
                  cum facilis quia facere ad, ea est corrupti odit obcaecati reprehenderit!</p>
                  <Link className='nav-link text-[#b88e2f] flex items-center gap-3'>Read more <FaLongArrowAltRight /></Link>
              </div>
              <div className="col-lg-5 flex flex-col gap-2 py-3 bg-[#fff3e3]">
                <FaStar size={30} />
                <h2 className='h4 text-[#b88e2f] font-bold'>Our Experience</h2>
                <p className='text-secondary'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                  Voluptatem qui quas, eveniet et natus nam sequi harum optio incidunt
                  cum facilis quia facere ad, ea est corrupti odit obcaecati reprehenderit!</p>
                  <Link className='nav-link text-[#b88e2f] flex items-center gap-3'>Read more <FaLongArrowAltRight /></Link>
              </div>
              <div className="col-lg-5 flex flex-col gap-2 py-3 bg-[#fff3e3]">
                <FaUsers size={30} />
                <h2 className='h4 text-[#b88e2f] font-bold'>Our Clients</h2>
                <p className='text-secondary'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                  Voluptatem qui quas, eveniet et natus nam sequi harum optio incidunt
                  cum facilis quia facere ad, ea est corrupti odit obcaecati reprehenderit!</p>
                  <Link className='nav-link text-[#b88e2f] flex items-center gap-3'>Read more <FaLongArrowAltRight /></Link>
              </div>
              <div className="col-lg-5 flex flex-col gap-2 py-3 bg-[#fff3e3]">
                <HiMiniBuildingOffice2 size={30} />
                <h2 className='h4 text-[#b88e2f] font-bold'>Our Approach</h2>
                <p className='text-secondary'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                  Voluptatem qui quas, eveniet et natus nam sequi harum optio incidunt
                  cum facilis quia facere ad, ea est corrupti odit obcaecati reprehenderit!</p>
                  <Link className='nav-link text-[#b88e2f] flex items-center gap-3'>Read more <FaLongArrowAltRight /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 flex items-center">
            <div className="col-lg-10 flex flex-col gap-2">
              <h2 className='display-5 font-bold text-[#b88e2f]'>Digital Experience in the Talent journey and Learning </h2>
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. In excepturi magni et aspernatur 
                veritatis praesentium delectus beatae, sint repellendus commodi! Praesentium officia excepturi 
                quos adipisci vero voluptatibus, repudiandae soluta, unde ad dolores, officiis nobis perferendis.</p>
                <button className='btn bg-[#b88e2f] hover:bg-[#d8b461] p-2 shadow-lg rounded-none text-light'>Learn more</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default About