import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FaHome } from "react-icons/fa";
// import { toast } from 'react-toastify';
import login_img from '../assets/login_img.png';
import { useAuthContext } from '../context';
import { useFormik } from 'formik';
import { LuLoader2 } from "react-icons/lu";
import * as yup from 'yup'

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { loginHandler, token,loader } = useAuthContext();
    const initialstate = {
        email:"",
        password:""
    }
    const LoginValidation = yup.object({
        email:yup.string().email().min(2).max(50).required("Please enter username or email"),
        password:yup.string().min(6).max(16).required("password is required")
    })

    const {values,errors,touched,handleChange,handleBlur,handleSubmit} = useFormik({
        initialValues:initialstate,
        validationSchema:LoginValidation,
        onSubmit:(value)=>{
            loginHandler(value)
        }
    })
    
    useEffect(() => {
        let id;
        if (token) {
          id = setTimeout(() => {
            navigate(location?.state?.from?.pathname ?? "/");
          }, 1000);
        }
    
        return () => {
          clearInterval(id);
        };
      }, [token]);
    
  return (
    <>
        <div className='conatiner-fluid min-h-[100vh] flex items-center justify-center'>
            <div className="container-sm relative">
            <div className='row items-center'>
                <div className='col-lg-6 d-none d-lg-block'>
                    <img src={login_img} alt="" className='object-cover'/>
                </div>
                <div className='col-lg'>
                    <span className='absolute top-4 right-4'><Link to='/' className='flex items-center gap-2 text-[#222]'><FaHome className='text-2xl text-[#222]' /> Back to home</Link></span>
                    <h2 className='text-3xl py-4 text-[#222] font-semibold'>Login here</h2>
                    <p>Not a existing User ? <Link to='/signup' className='text-primary font-semibold'>Sign in here</Link></p>
                    <form onSubmit={handleSubmit} className='w-full'>
                        <div className='form-group flex flex-col gap-2 py-3'>
                            <label htmlFor="email" className='h6'>Email</label>
                            <input 
                                type="email" 
                                placeholder='Enter your email' 
                                name='email'
                                className='form-control' 
                                defaultValue={values?.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && errors.email ? <p className='text-red-500'>{errors.email}</p> : null}
                            
                        </div>
                        <div className='form-group flex flex-col gap-2'>
                            <label htmlFor="password" className='h6'>Password</label>
                            <input 
                                type="password" 
                                placeholder='Enter your password' 
                                className='form-control' 
                                name='password'
                                value={values?.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.password && errors.password ? <p className='text-red-500'>{errors.password}</p> : null}
                        </div>
                        <div className='form-group flex py-3 items-center justify-between'>
                            <button type='submit' className='btn btn-dark bg-[#222] rounded-none w-25 flex items-center justify-center gap-2' disabled={loader} >
                                {loader ? <>Login <LuLoader2 className='animate-spin' /></>  :"Login"}
                            </button>
                            <p className='text-[14px]'>
                               Don't remember password ? <Link className='text-primary font-bold'>forgot now</Link>
                            </p>
                        </div>  
                    </form>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default Login