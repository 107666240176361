import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { GetProductUrl, } from "../../apis/ApiUrl";

export const ProductContext = createContext();

const ProductContextProvider = ({ children }) => {
  const [product, setProduct] = useState([]);
  const [allLoader,setAllLoader] = useState(false);
  const [category, setCategory] = useState([]);



 

  const getProduct = async () => {
    setAllLoader(true)
    try {
      const response = await axios.get(GetProductUrl);
  
      let data = response.data.data
      setProduct(data);
     
    } catch (error) {
      console.error("Error fetching product:", error);
      console.log(error)
    }finally{
      setAllLoader(false)
    }
  };

  const getProductCategory = async (GetCategoryUrl) => {
    try {
        const response = await axios.get('https://api.digiuncle.co.in/category/get-all');
        // Assuming response.data.data is an array of categories
        const categories = response.data.data;
        setCategory(JSON.parse(categories)) 
        return categories; // Returning categories for further processing if needed
    } catch (error) {
        console.error(error);
        throw error; // Re-throwing error for handling in the calling function if needed
    }
};
  
  useEffect(() => {
    getProduct();
    getProductCategory();
  
  }, []);

  return (
    <ProductContext.Provider value={{ product,allLoader, getProductCategory, category}}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContextProvider;
