import React, { useState } from 'react'
import { IoSearch, IoCloseSharp } from "react-icons/io5";
import { useProductContext } from '../../context';
import { Link } from 'react-router-dom';

const Search = () => {
    const {product} = useProductContext()
    const [search,setSearch] = useState("")
    const [data,setData] = useState([])

    const getSearchItem = (value)=>{
        let result = product.filter((item)=>{
            const cleanedString = item?.name?.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ').trim();
            const clearSearch = value?.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ').trim()
            return value && item && item.name && cleanedString?.toLowerCase().includes(clearSearch?.toLowerCase())
        })
        setData(result)
    }

    const onChangeSearch = (value)=>{
        setSearch(value)
        getSearchItem(value)
    }
  return (
    <>
        <div className='modal fade w-100' id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header flex justify-center">
                        <h3 className="text-center h4 text-dark fw-bold">What are you looking for ?</h3>
                        <button type="button" className="btn-close absolute top-5 right-5" data-bs-dismiss="modal" aria-label="Close"><IoCloseSharp size={26} /></button>
                    </div>
                    <div className="modal-body">
                        <form action="" className='flex items-center gap-3'>
                            <input type="text"  className='form-control' placeholder='type here ..' onChange={(e)=>onChangeSearch(e.target.value)} />
                            <IoSearch size={25} />
                        </form>
                    </div>
                    <div className='px-3'>
                                {data.map((item)=>{
                                    return (
                                        <div className='container-fluid transition-all' >
                                            <div className="container-sm">
                                                <ul className='nav flex-column'>
                                                    <li className='nav-item border-bottom hover:bg-slate-100' data-bs-dismiss="modal">
                                                        <Link className='nav-link text-secondary tracking-wider' to={`/product/${item.product_id}`}>{item?.name?.slice(0,150)}...</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Search